import React, { FunctionComponent } from "react";
import Header from "./header";

// in your cookie banner
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent, { Cookies } from "react-cookie-consent";

export type LayoutProps = { children, onHideNav, onShowNav, showNav, siteTitle };

import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const location = useLocation();

  const cookieAccepted = () => {
    //tag manager
    Cookies.set("gatsby-gdpr-google-tagmanager", "true");
    // google analytics
    Cookies.set("gatsby-gdpr-google-analytics", "true");
    //facebook pixel
    // Cookies.set("gatsby-gdpr-facebook-pixel", "true");
    //hotjar
    Cookies.set("gatsby-gdpr-hotjar", "true");
    initializeAndTrack(location);
  };

  const cookieDeclined = () => {};

  return (
    <>
      <Header
        siteTitle={props.siteTitle}
        onHideNav={props.onHideNav}
        onShowNav={props.onShowNav}
        showNav={props.showNav}
      />
      <div className={styles.content}>{props.children}</div>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.siteInfo}>
            &copy; {new Date().getFullYear()}, Built with{" "}
            <a href="https://www.sanity.io">Sanity</a> &amp;{" "}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </div>
          <div className={styles.siteInfo}>
            <a href="https://www.iubenda.com/privacy-policy/30506790">Privacy Policy</a>
          </div>
        </div>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Ok, cool"
        enableDeclineButton
        declineButtonText="No way"
        cookieName="gatsby-gdpr-google-tagmanager"
        onAccept={cookieAccepted}
        onDecline={cookieDeclined}
      >
        <span className={"cookie-banner-text"}>We use cookies</span>
      </CookieConsent>
    </>
  );
};

export default Layout;
